'use client';

import { LocalesProvider } from '@/components/ui-component/LocalesProviderClient';
import { getCookieValue } from '@/util/cookies';
import type { Language } from '@magicschool/supabase/types';
import { appTheme } from '@magicschool/theme';
import { Box } from '@magicschool/ui/Box';
import { Button } from '@magicschool/ui/Buttons/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { captureException } from '@sentry/nextjs';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);
  const resetPage = () => {
    location.reload();
  };
  const localeCookie = getCookieValue('locale');
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <LocalesProvider locale={localeCookie as Language} data-sentry-element="LocalesProvider" data-sentry-source-file="global-error.tsx">
          <ThemeProvider theme={appTheme} data-sentry-element="ThemeProvider" data-sentry-source-file="global-error.tsx">
            <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="global-error.tsx" />
            <Box display="flex" alignItems="center" justifyContent="center" height="100dvh" padding={2} data-sentry-element="Box" data-sentry-source-file="global-error.tsx">
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2} maxWidth={600} width="100%" data-sentry-element="Box" data-sentry-source-file="global-error.tsx">
                <Image src="/bunny-magic-right.gif" priority={true} width={350} height={217} style={{
                height: 'auto'
              }} alt="Teachers are magic banner" data-sentry-element="Image" data-sentry-source-file="global-error.tsx" />
                <Typography variant="h1" fontSize="2rem" fontWeight="normal" textAlign="center" marginY={2} data-sentry-element="Typography" data-sentry-source-file="global-error.tsx">
                  <FormattedMessage id="something-went-wrong" data-sentry-element="FormattedMessage" data-sentry-source-file="global-error.tsx" />
                </Typography>

                <Button variant="contained" ordinal="secondary" size="large" onClick={resetPage} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
                  <FormattedMessage id="try-again" data-sentry-element="FormattedMessage" data-sentry-source-file="global-error.tsx" />
                </Button>
                <Link href="/" data-sentry-element="Link" data-sentry-source-file="global-error.tsx">
                  <Button variant="outlined" ordinal="secondary" size="large" data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
                    <FormattedMessage id="return-home" data-sentry-element="FormattedMessage" data-sentry-source-file="global-error.tsx" />
                  </Button>
                </Link>
              </Box>
            </Box>
          </ThemeProvider>
        </LocalesProvider>
      </body>
    </html>;
}